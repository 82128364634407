import React, { Component } from "react";
import { FaReact, FaNodeJs, FaCss3Alt } from "react-icons/fa";
import { SiFirebase } from "react-icons/si";

const About = () => {
  return (
    <div className="about">
      <h1 id="pg-title">About The Artist</h1>
      <section className="artist-appear">
        <h3>Jean Cote</h3>
        <p>•</p>
        <h4>oil landscapes &amp; waterfronts</h4>
      </section>
      <div className="about-container">
        <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1599601821513-00e3f9fa1e6a%3Fixlib%3Drb-1.2.1%26ixid%3DMXwxMjA3fDB8MHxzZWFyY2h8Mnx8cGFpbnRpbmclMjBicnVzaHxlbnwwfHwwfA%253D%253D%26w%3D1000%26q%3D80&f=1&nofb=1"></img>
        <section className="artists-info">
          <h5>Jean C&ocirc;t&eacute;</h5>
          <h6>oil landscapes &amp; waterfronts</h6>
        </section>
        <hr id="break"></hr>
        <section className="artist-desc">
          <p>
            I have always loved art and became interested in art in 2018. I love
            painting waterscapes and landscapes.
          </p>
          <p>I hope you enjoy my art. Thank you for being here.</p>
        </section>
      </div>
      <section className="developer">
        <p>Website Developed by Julien C&ocirc;t&eacute;</p>
        <section>
          <p>Built with</p>
          <div className="dev-icons">
            <FaReact />

            <FaNodeJs />

            <FaCss3Alt />

            <SiFirebase />
          </div>
        </section>
      </section>
    </div>
  );
};

export default About;
