import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import React, { useState } from "react";

const Nav = ({ open, setOpen, toggleNav }) => {
  return (
    <nav>
      <section className="mobile-title">
        <h1>Jean's Art.</h1>
        <button id="burgie" onClick={toggleNav}>
          {open ? <AiOutlineClose /> : <HiOutlineMenuAlt4 />}
        </button>
      </section>
      <section className='mobile-container'>
        <div className={open ? "mobile-list-op" : "mobile-list-cl"}>
          <ul>
            <Link to="/" onClick={toggleNav}>
              <li>Home</li>
            </Link>
            <Link to="/art" onClick={toggleNav}>
              <li>Art</li>
            </Link>
            <Link to="/about" onClick={toggleNav}>
              <li>About</li>
            </Link>
            <Link to="/contact" onClick={toggleNav}>
              <li>Contact</li>
            </Link>
            <Link to="/payment" onClick={toggleNav}>
              <li>Payment</li>
            </Link>
          </ul>
        </div>
      </section>
    </nav>
  );
};

export default Nav;
