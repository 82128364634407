import Construction from "./Construction";

const Payment = () => {
    return (
        <Construction />
    )

}


export default Payment;