import {IoIosConstruct} from "react-icons/io"
const Construction = () => {
    return (
        <div className='construct'>
            <p>< IoIosConstruct/> <br></br><span>Under Construction</span></p>
            <p>Come back later :)</p>
        </div>
    )
}

export default Construction;