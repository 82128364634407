import { BsInstagram } from "react-icons/bs";
import { RiMessengerLine } from "react-icons/ri";
import { FiFacebook } from "react-icons/fi";

import { AiFillInstagram } from "react-icons/ai";
import { BsFacebook,BsMessenger } from "react-icons/bs";
import { SiGmail  } from "react-icons/si";
import { Routes, Route, Link } from "react-router-dom";
import Footer from './Footer.js';
const Landing = ({ open }) => {
  const socialLinks = {
    insta: 'https://instagram.com/cotejad?utm_medium=copy_link',
    messenger: 'https://www.facebook.com/jean.atkinson.3958',
    facebook: 'https://www.facebook.com/Art-for-the-soul-by-Jean-2230175590578296/',
    email: ''
  };
  return (
    <div className={open ? "container-dk" : "container"}>
      <div className="landing-wrapper">
        <h1>Welcome to <span id='jeans-art'>Jean's Art.</span></h1>
        <h2>Landscapes &amp; Waterfronts</h2>
        <Link to="/art">
          <button>view gallery</button>
        </Link>
        <section id="dots">• • •</section>
        <div className="sites-container">
          <section id="ig">
            <a href={socialLinks.insta} target="_blank"><AiFillInstagram /></a>
          </section>
          <section id="messenger">
            <a href={socialLinks.messenger} target="_blank"><BsMessenger /></a>
          </section>
          <section id="fb">
          <a href={socialLinks.facebook} target="_blank"><BsFacebook /></a>
          </section>
          <section id="email">
            <SiGmail />
          </section>
        </div>
        <Footer />
      </div>
      
    </div>
  );
};

export default Landing;
