import React, { Component } from "react";

const Art = ({ data }) => {
  return (
    <div className="art-container">
      <h1 id="pg-title">Art By Jean</h1>
      <section className='artwork-container'>
        {data.map((item) => {
          const { id, img, title, price, sold } = item;
          const status = sold ? "Sold" : "For Sale";

          return (
            <div className="artwork" key={id}>
              <img src={img}></img>
              <section>
                <p id="title">{title}</p>
                <p id="price">{price}</p>
                <p id={sold?"status-sold":"status"}>{status}</p>
              </section>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default Art;
