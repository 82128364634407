import { initializeApp } from 'firebase/app';
import { getFirestore,collection, getDocs, doc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDvCHVwVgKgmIlH8w3lOGtJkB1OoCJwA6A",
  authDomain: "jeancoteart.firebaseapp.com",
  databaseURL: "https://jeancoteart-default-rtdb.firebaseio.com",
  projectId: "jeancoteart",
  storageBucket: "jeancoteart.appspot.com",
  messagingSenderId: "1020536505943",
  appId: "1:1020536505943:web:30a41bc365aa71629e8512",
  measurementId: "G-M19L104E68",
};

initializeApp(firebaseConfig)
export const db = getFirestore()
export const colRef = collection(db,'art')





