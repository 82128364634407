import { AiOutlineCopyrightCircle } from "react-icons/ai";
const Footer = () => {
  return (
    <div>
      <footer>
        <AiOutlineCopyrightCircle />
        Jean's Art 2022. All Rights Reserved.
      </footer>
    </div>
  );
};

export default Footer;
