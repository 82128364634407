import Construction from "./Construction";
import React, {useRef} from 'react';
import emailjs from '@emailjs/browser'
import {init} from '@emailjs/browser'
init("QD7XQ4oiUYVooJQNt");

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_cy9fzlx', 'template_agfcu2t', form.current, 'QD7XQ4oiUYVooJQNt')
    .then((res) => console.log(res))
    .then(() => alert('Messaage has been sent successfully!\nPlease wait 3-5 business days to receive a reply.'))
    .catch((err) => alert(`Failed to send message.\nPlease try again later. Err ${err}`))
    document.getElementById('contact-form').reset();
  }

  return (
    <div className="contact-container">
      <h1 id="pg-title">Contact</h1>
      <div className="form-wrapper">
        <div className="form-container">
          <form ref={form} onSubmit={sendEmail} id='contact-form'>
            <p>
              <label>Name</label><br></br>
              <input type="text" name="from_name" required></input>
            </p>
            <p>
              <label>Email</label><br></br>
              <input type="email" name="from_email" required></input>
            </p>
            <p>
              <label>Message</label><br></br>
              <textarea rows='20'cols='30'name="message" required></textarea>
            </p>
            <button type='submit'>Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
