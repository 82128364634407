import { db, colRef } from "./firebaseConfig.js";
import { initializeApp } from 'firebase/app';
import { getFirestore,collection, getDocs, doc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import data from "./data.js";
import Nav from "./Nav.js";
import Landing from "./Landing.js";
import Art from "./Art.js";
import About from "./About.js";
import Contact from "./Contact.js";
import Payment from "./Payment.js";
import Footer from "./Footer.js";
import "./App.css";

function App() {
  const [open, setOpen] = useState(false);
  const [loadArt, setLoadArt] = useState(false);
  const [fbArt, setFbArt] = useState([]);

  const toggleNav = () => {
    if (window.innerWidth > 768){
      setOpen(false)
    }
    else {
      setOpen(!open);
    }
  };
  useEffect(() => {
    getDocs(colRef)
      .then((snapshot) => {
        let art = [];
        snapshot.docs.forEach((item) => {
          art.push({ ...item.data() });
          console.log(art)
        });
        setFbArt(art);
        
        
        setLoadArt(true)
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Helmet>
        <title>Jean's Art - Authentic Oil Paintings</title>
        <meta name="Authentic Oil Paintings" content="Welcome to Jean's Art" />
      </Helmet>
      <Nav open={open} setOpen={setOpen} toggleNav={toggleNav} />
      <div className={open ? "container-dk" : "container"}>
        <Routes>
          <Route path="/" element={<Landing open={open} />} />
          <Route path="/art" element={<Art data={fbArt} />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
      </div>
      
    </div>
  );
}

export default App;
